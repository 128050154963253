@import url("https://fonts.googleapis.com/css2?family=Allison&family=Ephesis&family=Hurricane&display=swap");

.inputs-area {
  margin-bottom: 16px;
  margin-top: 16px;
  .FormControl {
    label {
      align-self: flex-start;
    }
    .selectInput {
      border-radius: 4px;
      background-color: var(--light-color);
      fieldset {
        border-color: var(--border-color);
      }
      > div {
        padding: 10px 12px;
        height: 38px;
        box-sizing: border-box;
        text-align: start;
      }
      input {
        display: none;
      }
    }
    input{
      border-radius: 4px;
    }
  }
}
.signatureDiv {
  background-color: var(--border-color);
  padding: 16px;
  position: relative;
  min-height: 200px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .prevDv {
    top: calc(50% - 16px);
    left: -38px;
    margin: 0;
    color: var(--light-color) !important;
    display: block;
    padding: 5px 24px;
    position: absolute;
    min-width: 110px;
    transform: rotate(-90deg);
    text-align: center;
    font-weight: 600;
    border-radius: 0px 0px 4px 4px;
    background-color: var(--dark-color);
  }
  .signature {
    padding: 0 8px;
    // line-height: 87px;
  }
}
