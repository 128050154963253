.padContainer{
    padding: 32px;
    background-color: var(--bg-color);
    display: flex;
    flex-direction: column;
    .cnvs{
        background-color: var(--light-color);
        border: 1px dashed var(--text-color);
        align-self: flex-start;
        max-width: 100%;
    }
    .note{
        font-size: 14px;
        color: var(--text-color);
        margin: 8px 0 16px;
    }
    .actionsArea{
        display: flex;
        gap: 16px;
        margin: 16px 0 32px;
    }
}