.authPage {
//   background-color: var(--bg-color);
  background-color: var(--dark-color);
  min-height: 100vh;
//   padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .side{
    position: relative;
    z-index: 1;
    background-color: var(--light-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 900px) {
        background-color: rgba(255, 255, 255, 0.6);
    }
  }
  .logo {
    // padding-top: 15vh;
    margin-bottom: 32px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    z-index: 1;
  }
  .authCard {
    position: relative;
    z-index: 1;    
    background-color: transparent;
    box-shadow: none;
    .authCardContent {
      padding: 32px;
    }
    h1 {
      margin-top: 0;
    }
    .signButton {
      width: 100%;
      max-width: 200px;
    }
  }
  .bg {
    position: fixed;
    background-image: url(../../assets/imgs/bg.jpg);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    opacity: 1;
  }
}
