.mainContent{
    background-color: var(--bg-color);
    min-height: 100vh;
}
.asideHeader{
    .imgLogo{
        margin: auto;
        svg{
            height: 42px;
        }
    }
}