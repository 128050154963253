:root {
  --primary-color: #fec20e;
  --light-color: #fff;
  --dark-color: #1a3353;
  --text-color: #7f8fa4;
  --border-color: #e6ebf1;
  --blue-color: #1890ff;

  --nav-link-color: #7f8fa4;
  --nav-active-bg: #fec20e10;

  --bg-color: #f2f2f4;
  --bg-color-600: #e1e1e4;
}
* {
  box-sizing: border-box;
}
// .App {
//   text-align: center;
// }

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.warning {
  background-color: #fff6cd;
  color: #a38406;
  border: 1px solid #e7d27f;
  border-radius: 2px;
  padding: 8px 16px;
  font-weight: 600;
  margin: 0;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiButton-root {
  border-radius: 0.625rem !important;
  padding-right: 16px;
  padding-left: 16px;
}

.pdf-approval {
  height: 100vh;
  header {
    position: relative;
    z-index: 2;
    padding: 9px 32px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: var(--light-color);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    .logo {
      margin-inline-end: 32px;
      .icon-logo {
        display: block;
        svg {
          width: 86px;
          height: 43px;
        }
      }
    }
    .actions-btns {
      margin-inline-start: auto;
      display: flex;
      gap: 10px;
      button {
        &.save {
          // background-color: #1890ff;
          color: #1890ff;
        }
        .main-icon {
          svg {
            display: none;
          }
        }
        .MuiSvgIcon-root{
          font-size: 20px;
        }
        &.icon.large{
          background-color: var(--bg-color);
          .MuiSvgIcon-root{
            font-size: 20px;
          }
        }
        .txt{
          text-transform: capitalize;
          margin-inline-start: 4px;
        }
      }
      .MuiButtonGroup-root{
        background-color: var(--bg-color);
        border-radius: 25px;
        padding-right: 8px;
        padding-left: 8px;
      }
    }
    @media (max-width: 900px) {
      padding-right: 16px;
      padding-left: 16px;
      .actions-btns {
        button {
          padding-right: 0;
          padding-left: 0;
          min-width: 32px;
          width: 32px;
          height: 32px;
          border-radius: 4px !important;
          &.MuiButton-containedPrimary {
            color: #fec20e;
            background: transparent;
          }
          &.MuiButton-outlinedError {
            border-color: transparent;
          }
          &.save {
            color: #1890ff;
            background: transparent;
          }
          &.delete {
            color: #f33e3e;
            background: transparent;
          }
          .txt {
            display: none;
          }
          .main-icon {
            svg {
              display: block;
            }
          }
          .MuiButton-endIcon {
            display: none;
          }
        }
      }
      .back-btn{
        min-width: 24px;
        span{
          display: none;
        }
      }
      .page-controls {
        button {
          width: 32px;
          height: 32px;
          padding: 0 2px;
        }
        span {
          padding: 0 4px;
        }
      }
      .logo {
        margin-inline-end: 16px;
        .icon-logo {
          svg {
            width: 64px;
            height: 32px;
          }
        }
      }
    }
  }
  .page-controls {
    background: white;
    transition: opacity ease-in-out 0.2s;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    align-items: center;
    button {
      width: 36px;
      height: 36px;
      background: white;
      border: 0;
      font: inherit;
      font-size: 18px;
      border-radius: 4px;
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:enabled:hover,
      &:enabled:focus {
        background-color: #e6e6e6;
      }
      &:enabled:hover {
        cursor: pointer;
      }
      &:disabled {
        opacity: 0.6;
      }
    }
    span {
      font: inherit;
      font-size: 12px;
      padding: 0 4px;
    }
  }

  .pdf-doc-wrapper {
    position: absolute;
    width: 100%;
    height: calc(100vh - 66px);
    top: 66px;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #f3f3f3;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    .react-pdf__Page__textContent,
    .react-pdf__Page__annotations {
      display: none;
    }
    .docshadow {
      position: relative;
      .signature_images,
      .stamp_images {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        overflow: hidden;
      }
    }
  }
}
.ijtmaati-signature-modal {
  &.MuiModal-root {
    overflow-y: auto;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .modal-box {
    padding: 0;
    border-radius: 8px;
  }
  .modal-title {
    font-size: 18px;
    padding: 16px;
    border-bottom: 1px solid #ddd;
  }
  .ijtmaati-signature-modal-content {
    padding: 16px;
    .draw-container {
      .draw-container-heading {
        display: flex;
        .drower-pens {
          display: flex;
          gap: 12px;
          align-items: center;
          margin-inline-start: auto;
        }
        .toggle {
          .MuiFormGroup-root {
            cursor: pointer;
          }
        }
      }
    }
    .draw-container {
      padding: 0 30px;
      .drower-pens {
        display: flex;
        justify-content: flex-end;
        .drower-pen {
          width: 20px;
          height: 20px;
          margin-right: 20px;
          border-radius: 50%;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
      .draw-area {
        width: 740px !important;
        margin: 20px 0;
        position: relative;
        .preview{
          &:before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 12;
          }
        }
        .sigCanvas {
          position: relative;
          z-index: 1;
          width: 740px !important;
          height: 300px !important;
          max-width: 100%;
          max-height: 100%;
          background: #ffffff 0% 0% no-repeat padding-box !important;
          border: 1px dashed #7f8fa4 !important;
          border-radius: 10px !important;
          cursor: crosshair;
        }
        @media (max-width: 480px) {
          width: 300px !important;
        }
        @media (min-width: 481px) and (max-width: 576px) {
          width: 400px !important;
        }
        @media (min-width: 577px) and (max-width: 768px) {
          width: 500px !important;
        }
        &.selected-sig{
          // width: auto!important;
          .sigCanvas{
            width: auto!important;
            height: auto!important;
          }
        }
      }
      p {
        font-size: 15px;
        color: #7f8fa4;
        text-align: center;
      }
    }

    .signature-images {
      padding: 0 30px 30px;
      .signature-upload-btn {
        width: 360px;
        height: 72px;
        background: #fec20e03;
        border-radius: 10px;
        color: #fec20e;
        line-height: 72px;
        position: relative;
        @media (max-width: 576px) {
          width: 300px;
        }
        svg {
          vertical-align: middle;
          margin: 0 15px;
        }
        input {
          position: absolute;
          width: 100%;
          height: 100%;
          display: block;
          top: 0;
          left: 0;
          opacity: 0;
          cursor: pointer;
        }
      }
      .signature-image-container {
        width: 360px;
        height: 72px;
        background: #7f8fa41a;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
        @media (max-width: 576px) {
          width: 300px;
        }
        cursor: pointer;
        .ant-checkbox-wrapper {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          margin: 0 20px;
        }
      }
    }
    .footer-btn {
      display: flex;
      gap: 10px;
    }
    @media (max-width: 500px) {
      .draw-container {
        padding: 0;
      }
    }
  }
  // @media (max-width: 500px) {
  //   .modal-box {
  //     // padding: 16px;
  //     // width: 100%;
  //     // height: 100%;
  //   }
  // }
}

.signature-resize-drag,
.stamp-resize-drag {
  background-color: transparent;
  background: none;
  touch-action: none;
  /* This makes things *much* easier */
  box-sizing: border-box;
  position: absolute;
  top: 10px;
  left: 10px;
  border: 1px dashed var(--text-color);
  &[selected] {
    border-color: var(--primary-color);
    .rs-hdlr{
      display: block;
      border-color: var(--primary-color);
      background-color: #fff;
      border-width: 2px;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .rs-hdlr{
    display: none;
    position: absolute;
    width: 12px;
    height: 12px;
    border: 1px solid var(--text-color);
    background-color: #fff;
    transform: rotate(45deg);
  }
  .rs-hdl{
    &-t{
      top: -6px;
      left: -6px;
    }
    &-r{
      top: -6px;
      right: -6px;
    }
    &-b{
      bottom: -6px;
      right: -6px;
    }
    &-l{
      bottom: -6px;
      left: -6px;
    }
  }
  .info-wrapper{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding-block-start: 2px;
    padding-block-end: 0px;
    padding-inline-start: 8px;
    padding-inline-end: 0px;

    inset-inline-start: 100%;
    inset-block-start: 0;

    gap: 6px;
    position: absolute;
    .title{
      font-size: 11px;
    }
    .reason{
      margin: 0;
      line-height: 14px;
    }
  }
  &.options-bottom{
    .info-wrapper{
      inset-inline-start: 0;
      inset-block-start: 100%;
      
      padding-block-start: 8px;
      padding-block-end: 0px;
      padding-inline-start: 2px;
      padding-inline-end: 0px;
  
    }
  }
}
.isScalled{
  .rs-hdlr{
    width: 24px;
    height: 24px;
  }
  .rs-hdl{
    &-t{
      top: -12px;
      left: -12px;
    }
    &-r{
      top: -12px;
      right: -12px;
    }
    &-b{
      bottom: -12px;
      right: -12px;
    }
    &-l{
      bottom: -12px;
      left: -12px;
    }
  }  
}
.stamp-resize-drag {
  // &:hover,
  &[selected] {
    border-color: #09caff;
    .rs-hdlr{
      background-color: #fff;
      border-color: #09caff;
    }    
  }
}
.ijtmaati-signature-modal
  .ijtmaati-signature-modal-content
  .draw-container
  .draw-area
  .sigCanvas.img-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
  border: none !important;
  background: none !important;
  height: auto !important;
  margin-top: 32px;
}
.signature-settings {
  .ijtmaati-signature-modal {
    background-color: var(--bg-color);
    .ijtmaati-signature-modal-content {
      padding: 32px;
      border-radius: 8px;
      .draw-container {
        padding: 0;
        .draw-area {
          margin: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
  .actions {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    margin-top: 16px;
    gap: 16px;
    button {
      font-size: 13px;
      svg {
        font-size: 18px;
        margin-inline-end: 6px;
      }
      @media(max-width: 600px){
        font-size: 10px;
        svg {
          font-size: 12px;
          margin-inline-end: 2px;
        }        
      }
    }
  }
  .input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
  }
}
.signatures_preview_list {
  background-color: var(--bg-color-600);
  padding: 0 16px 16px;
  margin-top: 64px;
  border-radius: 4px;
  .sigCanvasPreview {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: transparent;
      z-index: 2;
    }
    .pad-wrapper {
      .sigCanvasPad {
        background-color: var(--light-color);
        border-radius: 2px;
        position: relative;
        z-index: 1;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .title {
      font-size: 13px;
      font-weight: 400;
      margin: 0;
    }
  }
}

.pdf-doc-wrapper {
  .pdfDoc {
    // max-width: 100%;
    // transform: scale(0.64);
    .react-pdf__Page__canvas {
      // max-width: 100%;
    }
  }
  .signature_images {
    // transform: translateX(-50%) scale(0.64)!important;
  }
}
.MuiTableCell-body {
  color: var(--text-color) !important;
}
.main-title-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.main-title {
  text-align: start;
  margin: 0 0 16px;
  font-size: 32px;
}
.inner-title {
  margin: 0 0 16px;
  font-size: 18px;
  text-align: start;
}
.pinding-status {
  // background-color: #fffbe4;
  border-radius: 20px;
  display: inline-block;
  padding: 4px 8px;
  color: #e2b502;
  // border: 1px solid #e2b502;
  // width: 82px;
}
.done-status {
  // background-color: #e4ffed;
  border-radius: 20px;
  display: inline-block;
  padding: 4px 8px;
  color: #01af3b;
  // border: 1px solid #01af3b;
  // width: 82px;
}
.nav-link {
  text-decoration: none;
  color: var(--nav-link-color);
  svg {
    fill: var(--nav-link-color);
    font-size: 16px;
  }
  li {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      right: -1px;
      top: 0;
      width: 3px;
      height: 100%;
      background-color: transparent;
    }
  }
  &.active {
    li {
      background-color: var(--nav-active-bg);
      &:after {
        background-color: var(--primary-color);
      }
    }
    color: var(--primary-color);
    svg {
      fill: var(--primary-color);
    }
  }
}
.profile-card {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  form {
    width: 100%;
  }
}

form {
  .form-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
label {
  color: var(--dark-color);
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
}
input[type=text],input[type=email], textarea{
  border-radius: 8px;
  border: 1px solid var(--border-color);
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  min-width: 0;
  color: #455560;
  font-size: 14px;
  background-color: var(--light-color);
  background-image: none;
  transition: all 0.3s;
  padding: 10px 16px;
  box-shadow: none;
  outline: none;
  &:hover,
  &:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px #fec20e20;
  }
  &:disabled {
    background-color: var(--bg-color);
    cursor: not-allowed;
    box-shadow: none;
    outline: none;
    border-color: var(--border-color);
  }
}

.add-signature-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 !important;
  max-width: 100%;
  width: 220px;
  height: 220px;
  border: 2px dashed var(--primary-color) !important;
  color: var(--primary-color) !important;
  background-color: var(--nav-active-bg) !important;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: var(--nav-bg) !important;
  }
  .img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: contain;
    background-color: var(--light-color);
  }
}

.stamps-list {
  margin: 16px 0;
  width: 100%;
  .item-stamp {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .title {
      margin: 8px 0 0;
      text-align: start;
      font-size: 14px;
    }
  }
  .img {
    width: 100%;
    height: 114px;
    object-fit: contain;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 4px;
    background-color: var(--light-color);
  }
}
.files-wrapper{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  .file-item{
    max-width: 180px;
    background-color: var(--bg-color);
    display: flex;
    align-items: center;
    padding: 2px 8px;
    border-radius: 4px;
    .info{
      flex: 0 0 calc(100% - 40px);
      width: calc(100% - 40px);
      color: var(--text-color);
      .title{
        white-space: nowrap;
        margin: 0;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
      .size{
        display: block;
        font-size: 11px;
        line-height: 14px;
      }
    }
    button{
      margin-inline-start: auto;
      width: 40px;
    }
  }
  .upButton{
    font-size: 13px;
    white-space: nowrap;
  }
}
.ratio-21x9 {
  position: relative;
  --bs-aspect-ratio: 42.8571428571%;
  &:before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: "";
  }  
  &>*{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%!important;
    height: 100%!important;    
  }
}

@media (max-width: 900px) {
  .create_approval_btn{
    min-width: 38px;
    min-height: 38px;
    .text{
      display: none;
    }
    span{
      margin: 0!important;
    }
  }
  .download_btn{
    span{
      margin: 0!important;
    }
  }
}
@media (max-width: 500px) {
  .pdf-approval header{
    padding: 8px 8px;
    .actions-btns{
      gap: 6px;
      button.save{
        border-radius: 100px;
        background-color: transparent!important;
        .MuiSvgIcon-root{
          font-size: 24px!important;
        }
      }
    }
    .logo .icon-logo svg {
      width: auto!important;
      height: 18px!important;
    }    
    .back-btn {
      margin-inline-end: 8px;
    }    
  }
  .pdf-nav {
    position: fixed;
    bottom: 16px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .main-title{
    font-size: 22px;
    padding-top: 3px;
  }
  .MuiTableCell-body{
    padding: 8px 2px 2px!important;
  }
  .ijtmaati-signature-modal .modal-box{
    max-width: 340px;
    max-width: calc(100% - 16px);
  }
  .ijtmaati-signature-modal-content{
    [role="tabpanel"]{
      .MuiBox-root{
        padding: 0!important;
      }
    }
  }
  .create_approval_btn{
    width: 38px;
    min-width: 38px!important;
    height: 38px;
    padding-right: 8px!important;
    padding-left: 8px!important;
  }
}